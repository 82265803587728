import React from 'react';
import logo from "../../../images/icon.png"
import "./Logo.css"

const Logo = () => {
    return (
        <div className="logo">
        <img src={logo}></img>
        <h3 className="logo-text">George Nance</h3>
        </div>
    );
};

export default Logo;