import React,{Component} from "react"
import { Link, graphql,useStaticQuery } from "gatsby"
import "./header.css"
import Logo from "./Logo/Logo"

const ListLink = props => (
  <li className="link">
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Header extends Component{
  constructor(data){
    super(data)
    
  } 

  render(){
    return (
      <header className="header">
        <Link to="/" >
<Logo></Logo>
        </Link>
        <ul style={{ listStyle: `none`, float: `right` }}>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/about/">About</ListLink>
          < ListLink to = "/portfolio/" > Portfolio </ListLink>
          <ListLink to="/contact/">Contact</ListLink>
        </ul>
      </header>
  )
  }
}

export default Header;
