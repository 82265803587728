import React, { Component } from 'react';
import "./footer.css"
import twitter from "../../images/twitter.svg"
import github from "../../images/github.svg"
import { StaticQuery, graphql } from "gatsby"
class Footer extends Component {
    //   constructor to set state and bind "this"
      constructor(data) {
        super(data);
        // this.handleClick = this.handleClick.bind(this);
      }


    render() {
    return(
        <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title,
            social {
                github
                twitter
              }
          }
        }
      }
    `}

    render={data =>(<div className="footer">
            <div className="socialIcons">
            <a href={`http://github.com/`+data.site.siteMetadata.social.github}><img src={github} alt="github"></img></a>
            <a href={`http://twitter.com/`+data.site.siteMetadata.social.twitter}><img src={twitter} alt="twitter"></img></a>
            </div>

            <p> &copy; {new Date().getFullYear()} {data.site.siteMetadata.title}</p>
        </div>
    )}/>
    )}
}



export default Footer;