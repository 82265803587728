import React from "react"

import Header from "./Header/header"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"
import Footer from "./Footer/footer"
import { graphql } from "gatsby"

export default ({ children,data }) => {
  console.log(data);

  return (

  <div className="container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>George Nance - My blog, portfolio, and development website</title>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
        </Helmet>
    
      <Header></Header>
      {children}
      <Footer ></Footer>
  </div>)
}
